.ad-table thead>tr>th{
    border-color: #123c6c;
}
.ad-table thead>tr>th{
    text-transform: uppercase;
    background: #123c6c;
    color: #fff;
}
.ad-table thead.main-head>tr>th{
    border: 1px solid #fff;
}.ad-table tbody.main-body>tr>td{
    padding: 0;
}
 .sub-table{
     border-right: 1px solid #fff;
 }
 .table{
     margin: 0;
 }

 .data_table thead>tr>th{
     text-transform: uppercase;
     background: #123c6c;
     color: #fff !important;
     padding: 10px 0px;
     font-weight: 600;
     font-size: 9px;
     text-align: center;
     max-width: 100px;
     word-break: break-all;
     line-height: 1.3;
 }
.data_table tbody>tr>td{
    text-transform: uppercase;
    padding: 10px 0px;
    /*text-align: center !important;*/
    font-size: 12px;
}

.MuiTableSortLabel-root:hover {
    color: white!important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: white!important;
}

.top_table_head th{
    border-right: 1px solid #fff;
}
.middle_table_head>th:first-child{
    padding-left: 10px;
    width: 120px;
}
.middle_table_head>th:nth-child(5),.middle_table_head>th:nth-child(12){
    border-right: 1px solid #fff;
}
.middle_table_head>th:nth-child(6), .middle_table_head>th:nth-child(10),.middle_table_head>th:nth-child(13),.middle_table_head>th:nth-child(16),
.middle_table_head>th:nth-child(18){
    padding-left: 5px;
}

.middle_table_head.nfl_head>th:nth-child(9), .middle_table_head.nfl_head>th:nth-child(15),.middle_table_head.nfl_head>th:nth-child(17){
    border-right: 1px solid #fff;
}

.table_input{
    width: 60px;
    padding: 2px 0px !important;
    border: 1px solid #ddd !important;
}
.table_input .MuiInput-underline:before,.table_input .MuiInput-underline:after,.table_input .MuiInput-underline:hover:not(.Mui-disabled):before{

    border-bottom:  none;
}
.table_input .MuiInputBase-input{
    font-size: 12px;
}
.MuiButton-contained.btn-danger.Mui-disabled{
    background-color: rgba(181, 12, 12, 0.76) !important;
}

.BKN{
    background: url("../../images/team_logo.svg") -60px -60px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
}
.LAL {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -392px -392px;
    vertical-align: middle;
}
.GS {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -270px -270px;
    vertical-align: middle;
}
.SAC {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -746px -753px;
    vertical-align: middle;
}
.POR {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -718px -722px;
    vertical-align: middle;
}
.DEN {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -207px -212px;
    vertical-align: middle;
}

.OKC {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -600px -600px;
    vertical-align: middle;
}
.MEM {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -420px -420px;
    vertical-align: middle;
}
.CHA {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -88px -90px;
    vertical-align: middle;
}
.NOP {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -540px -540px;
    vertical-align: middle;
}
.HOU {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -300px -300px;
    vertical-align: middle;
}
.ATL {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") 0px 0px;
    vertical-align: middle;
}
.MIN {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") 507px 511px;
    vertical-align: middle;
}
.CHI {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -118px -122px;
    vertical-align: middle;
}
.CLE {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -150px -151px;
    vertical-align: middle;
}
.DET {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -240px -241px;
    vertical-align: middle;
}
.TOR {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("../../images/torantoRaptors.png");
    background-size: cover;
    vertical-align: middle;
}
.PHI {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -658px -664px;
    vertical-align: middle;
}
.SAS {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -780px -780px;
    vertical-align: middle;
}
.ORL {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -632px -632px;
    vertical-align: middle;
}
.IND {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -329px -330px;
    vertical-align: middle;
}
.NYK {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -570px -570px;
    vertical-align: middle;
}
.BOS {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -29px -30px;
    vertical-align: middle;
}
.UTA {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -840px -840px;
    vertical-align: middle;
}
.MIA{
    background: url("../../images/team_logo.svg") -450px -450px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
}
.DAL{
    background: url("../../images/team_logo.svg") -180px -180px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
}
.WAS{
    background: url("../../images/team_logo.svg") -869px -872px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
}

.MIL{
    background: url("../../images/team_logo.svg") -480px -480px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
}
.PHO {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -690px -690px;
    vertical-align: middle;
}

.MIL{
    background: url("../../images/team_logo.svg") -480px -480px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
}
.PHO {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -690px -690px;
    vertical-align: middle;
}
.nfl.CAR {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -120px -120px !important;
    vertical-align: middle;
}
.nfl.NYJ {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -690px -690px !important;
    vertical-align: middle;
}
.nfl.TEN {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -900px -900px !important;
    vertical-align: middle;
}
.nfl.ARI {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") 0px 0px !important;
    vertical-align: middle;
}
.nfl.NO {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -630px -630px !important;
    vertical-align: middle;
}
.nfl.GB {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -330px -330px !important;
    vertical-align: middle;
}
.nfl.KC {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -450px -450px !important;
    vertical-align: middle;
}
.nfl.CLE {
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -210px -210px !important;
    vertical-align: middle;
}
.nfl.IND {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -390px -390px !important;
    vertical-align: middle;
}
.nfl.SEA {
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -840px -840px !important;
    vertical-align: middle;
}
.nfl.MIN {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -570px -570px !important;
    vertical-align: middle;
}
.nfl.CIN {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -180px -180px !important;
    vertical-align: middle;
}
.nfl.DET {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -300px -300px !important;
    vertical-align: middle;
}
.nfl.SF {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -810px -810px !important;
    vertical-align: middle;
}
.nfl.WAS {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -930px -930px !important;
    vertical-align: middle;
}
.LAC {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -363px -360px;
    vertical-align: middle;
}

.nfl.LAC{
    width: 25px;
    height: 25px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("../../images/chargerLAC.png");
    background-size: cover;
    vertical-align: middle;
}
.nfl.DAL {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -240px -240px !important;
    vertical-align: middle;
}
.nfl.TB {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -870px -870px !important;
    vertical-align: middle;
}
.nfl.LV {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -720px -720px !important;
    vertical-align: middle;
}
.nfl.BAL {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -60px -60px !important;
    vertical-align: middle;
}
.nfl.HOU {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -360px -360px !important;
    vertical-align: middle;
}
.nfl.JAX {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -420px -420px !important;
    vertical-align: middle;
}
.nfl.DEN {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -270px -270px !important;
    vertical-align: middle;
}
.nfl.NYG {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -660px -660px !important;
    vertical-align: middle;
}
.nfl.PIT {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -780px -780px !important;
    vertical-align: middle;
}
.nfl.BUF {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -90px -90px !important;
    vertical-align: middle;
}
.nfl.CHI {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -150px -150px !important;
    vertical-align: middle;
}
.nfl.LAR {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -510px -510px !important;
    vertical-align: middle;
}
.nfl.MIA {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -540px -540px !important;
    vertical-align: middle;
}
.nfl.NE {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -600px -600px !important;
    vertical-align: middle;
}
.nfl.ATL {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -30px -30px !important;
    vertical-align: middle;
}
.nfl.PHI {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/nfl_team.svg") -750px -750px !important;
    vertical-align: middle;
}

.gameDataButG{
   width :68%
}

.gameDataBut {
    border: 1px solid;
    border-radius: 4px 0px 0px 4px;
    padding: 6px 22px;
    font-size: 11px;
}

.gameDataBut:hover{
    background-color: blue;
}

.gameDataButt{
    margin-left: 5px;
    border: 1px solid ;
    border-radius: 0px 4px 4px 0px;
    padding: 6px 22px;
    font-size: 11px;
}

.gameDataButt:hover{
    background-color: blue;
}

.ACTIVE {
    background-color: #123C6C !important;
    color: white !important;
}
.NO{
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    display: inline-block;
    background: url("../../images/team_logo.svg") -540px -540px;
    vertical-align: middle;
}

/*      NBA WITH IDs      */

.NBA1{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/1.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA2{
    width: 22px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/2.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA3{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/3.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA4{
    width: 18px;
    height: 22px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/4.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA5{
    width: 25px;
    height: 22px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/5.png");
    background-size: cover;
    vertical-align: middle;
}
.NBA6{
    width: 26px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/6.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA7{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/7.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA8{
    width: 18px;
    height: 19px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/8.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA9{
    width: 20px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/9.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA10{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/10.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA11{
    width: 22px;
    height: 23px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/11.png");
    background-size: cover;
    vertical-align: middle;
}
.NBA12{
    width: 11px;
    height: 24px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/12.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA13{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/13.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA14{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/14.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA15{
    width: 18px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/15.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA16{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/16.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA17{
    width: 18px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/17.png");
    background-size: cover;
    vertical-align: middle;
}
.NBA18{
    width: 21px;
    height: 24px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/18.png");
    background-size: cover;
    vertical-align: middle;
}
.NBA19{
    width: 20px;
    height: 23px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/19.png");
    background-size: cover;
    vertical-align: middle;
}
.NBA20{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/20.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA21{
    width: 21px;
    height: 18px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/21.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA22{
    width: 19px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/22.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA23{
    width: 24px;
    height: 24px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/23.png");
    background-size: cover;
    vertical-align: middle;
}
.NBA24{
    width: 18px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/24.png");
    background-size: cover;
    vertical-align: middle;
}
.NBA25{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/25.png");
    background-size: cover;
    vertical-align: middle;
}
.NBA26{
    width: 18px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/26.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA27{
    width: 28px;
    height: 18px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/27.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA28{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/28.png");
    background-size: cover;
    vertical-align: middle;
}
.NBA29{
    width: 20px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/29.svg");
    background-size: cover;
    vertical-align: middle;
}
.NBA30{
    width: 20px;
    height: 24px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nbateamlogo.s3.us-east-2.amazonaws.com/30.svg");
    background-size: cover;
    vertical-align: middle;
}

/*      NFL WITH IDs      */

.NFL1{
    width: 23px;
    height: 20px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/1.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL2{
    width: 24px;
    height: 22px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/2.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL3{
    width: 34px;
    height: 17px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/3.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL4{
    width: 27px;
    height: 17px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/4.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL5{
    width: 26px;
    height: 15px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/5.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL6{
    width: 25px;
    height: 16px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/6.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL7{
    width: 24px;
    height: 18px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/7.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL8{
    width: 22px;
    height: 18px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/8.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL9{
    width: 22px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/9.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL10{
    width: 30px;
    height: 17px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/10.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL11{
    width: 27px;
    height: 20px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/11.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL12{
    width: 28px;
    height: 18px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/12.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL13{
    width: 22px;
    height: 19px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/13.svg");
    background-size: cover;
    vertical-align: middle;
}.NFL14{
     width: 20px;
     height: 21px;
     margin-right: 5px;
     background-repeat:  no-repeat;
     display: inline-block;
     background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/14.svg");
     background-size: cover;
     vertical-align: middle;
 }.NFL15{
      width: 25px;
      height: 19px;
      margin-right: 5px;
      background-repeat:  no-repeat;
      display: inline-block;
      background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/15.svg");
      background-size: cover;
      vertical-align: middle;
  }.NFL16{
       width: 28px;
       height: 18px;
       margin-right: 5px;
       background-repeat:  no-repeat;
       display: inline-block;
       background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/16.svg");
       background-size: cover;
       vertical-align: middle;
   }
.NFL19{
    width: 25px;
    height: 19px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/19.svg");
    background-size: cover;
    vertical-align: middle;
}.NFL20{
     width: 22px;
     height: 25px;
     margin-right: 5px;
     background-repeat:  no-repeat;
     display: inline-block;
     background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/20.svg");
     background-size: cover;
     vertical-align: middle;
 }
.NFL21{
      width: 33px;
      height: 17px;
      margin-right: 5px;
      background-repeat:  no-repeat;
      display: inline-block;
      background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/21.svg");
      background-size: cover;
      vertical-align: middle;
  }
.NFL22{
    width: 20px;
    height: 24px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/22.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL23{
    width: 22px;
    height: 16px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/23.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL24{
    width: 27px;
    height: 17px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/24.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL25{
    width: 20px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/25.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL26{
    width: 26px;
    height: 19px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/26.svg");
    background-size: cover;
    vertical-align: middle;
}

.NFL28{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/28.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL29{
    width: 33px;
    height: 15px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/29.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL30{
    width: 37px;
    height: 16px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/30.svg");
    background-size: cover;
    vertical-align: middle;
}.NFL31{
     width: 30px;
     height: 18px;
     margin-right: 5px;
     background-repeat:  no-repeat;
     display: inline-block;
     background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/31.svg");
     background-size: cover;
     vertical-align: middle;
 }.NFL32{
      width: 25px;
      height: 18px;
      margin-right: 5px;
      background-repeat:  no-repeat;
      display: inline-block;
      background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/32.svg");
      background-size: cover;
      vertical-align: middle;
  }
.NFL33{
    width: 22px;
    height: 20px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/33.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL34{
    width: 26px;
    height: 18px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/34.svg");
    background-size: cover;
    vertical-align: middle;
}
.NFL35{
    width: 21px;
    height: 21px;
    margin-right: 5px;
    background-repeat:  no-repeat;
    display: inline-block;
    background: url("https://nflteamlogo.s3.us-east-2.amazonaws.com/35.jpg");
    background-size: cover;
    vertical-align: middle;
}

.group-buttons{
    display: flex;
    overflow-x: auto;
    padding: 15px 0;
    white-space: nowrap;
    justify-content: flex-start;
}

/*.group-buttons{*/
/*    width: 100%;*/
/*    display: flex;*/
/*    overflow-x: auto;*/
/*    padding: 15px 0;*/
/*    justify-content: flex-start;*/
/*    !*white-space: nowrap;*!*/
/*}*/
/* width */
.group-buttons::-webkit-scrollbar {
    height: 12px;
    border-radius: 20px;
    cursor: pointer;
}

/* Track */
.group-buttons::-webkit-scrollbar-track {
    background: #5d5d5d;
    border-radius: 20px;
    cursor: pointer;
}

/* Handle */
.group-buttons::-webkit-scrollbar-thumb {
    background: #183b6c;
    border-radius: 20px;
    cursor: pointer;
}

/* Handle on hover */
.group-buttons::-webkit-scrollbar-thumb:hover {
    background: #123C6C;
    border-radius: 20px;
    cursor: pointer;
}


.common-button{
    margin-left: 7px;
}
.common-button:first-child{
    margin-left: 0;
}
.common-button:last-child{
    margin-right: 0;
}

.btn-primary{
    color: #123C6C;
    background-color: #fff;
    border: 1px solid #ddd;
    font-size: 20px;
    text-transform: uppercase;
}

.btn-primary:hover{
    color: #fff;
    background-color: #123C6C;
}
.btn-primary.focus, .btn-primary:focus{
    color: #fff;
    background-color: #123C6C;
}
.ad-group-btn{
    display: table;
    min-height: 40px;
    padding: 3px 5px;
    font-size: 14px;
}
.multiple-btn .ad-group-btn{
    padding: 0;
}
.ad-group-btn input{
    display: none;
}
.ad-group-btn .btn-img-logo{
    width: 30px;
    margin-right: 10px;
}
.ad-group-btn .btn-text{
    display: table-cell;
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 14px;
}
.multiple-btn .btn-text{
    display: inline-block;
    padding: 10px;
}
.ad-group-btn .btn-img{
    display: table-cell;
    vertical-align: middle;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle{
    color: #fff;
    background-color: #123C6C;
    border-color: #123C6C;
}
.search_input .MuiOutlinedInput-input{
    /*padding: 11.5px 14px;*/
    /*min-width: 90px;*/
    /*max-width: 110px;*/
    padding-bottom: 2%;
    width: 100px;
    padding-left: 15px;
}

.toasterStyle .Toastify__toast--default{
    background: #3498db;
    color: #fff;
}
.toasterStyle .Toastify__progress-bar--default{
    background: rgba(255, 255, 255, 0.7);
}
.toasterStyle .Toastify__toast-body{
    font-size: 12px;
    padding: 6px;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    box-shadow: none;
}
.dropdownBtn{
    border: 1px solid #ddd !important;
    color: #123C6C !important;
}
.dropdownBtn:hover,
.dropdownBtn:active,
.dropdownBtn:focus{
    color: #fff !important;
    background: #123C6C !important;
}
.MuiList-padding, .MuiMenuItem-root{
    padding: 0 !important;
}
.menu_list_head{
    display: block;
    width: 100%;
}
.mr-0{
    margin: 0;
}
.left_team,
.middle_team,
.right_team{
    text-align: center;
    font-size: 13px;
}
.left_team,.right_team{

    border-bottom: 3px solid #333;
}
.middle_team{
    padding: 5px 10px;
    line-height: 2.4;
}

.left_team .team_name,
.right_team .team_name{
    text-transform: uppercase;
    display: block;
    color: #000;
    padding: 5px 10px;
}
.left_team,
.right_team {
    min-width: 100px;
    padding: 5px;
}
.left_des, .right_des{
    text-align: center;
    font-size: 12px;
}
.left_des,
.middle_des,
.right_des{
    cursor: initial;
    user-select: none;
}

.left_des{
    text-transform: capitalize;
}
.right_des{
    text-transform: uppercase;
}
.team_container .active{
    color: #fff !important;
    background: #123c6c !important;
}

/*   NBA  */

.team_container .activeNBA1{
    color: #fff !important;
    background: #002B5C !important;
}

.activeNBA1{
    color: #fff !important;
    background: #002B5C !important;
}

.team_container .activeNBA2{
    color: #fff !important;
    background: #00788C !important;
}

.activeNBA2{
    color: #fff !important;
    background: #00788C !important;
}

.team_container .activeNBA3{
    color: #fff !important;
    background: #C8102E !important;
}

.activeNBA3{
    color: #fff !important;
    background: #C8102E !important;
}

.team_container .activeNBA4{
    color: #fff !important;
    background: #98002E !important;
}

.activeNBA4{
    color: #fff !important;
    background: #98002E !important;
}

.team_container .activeNBA5 {
    color: #fff !important;
    background: #0077C0 !important;
}

.activeNBA5 {
    color: #fff !important;
    background: #0077C0 !important;
}

.team_container .activeNBA6{
    color: #fff !important;
    background: #006BB6 !important;
}

.activeNBA6{
    color: #fff !important;
    background: #006BB6 !important;
}

.team_container .activeNBA7{
    color: #fff !important;
    background: #006BB6 !important;
}

.activeNBA7{
    color: #fff !important;
    background: #006BB6 !important;
}

.team_container .activeNBA8 {
    color: #fff !important;
    background: #000000 !important;
}

.activeNBA8 {
    color: #fff !important;
    background: #000000 !important;
}

.team_container .activeNBA9 {
    color: #fff !important;
    background: #008348 !important;
}

.activeNBA9 {
    color: #fff !important;
    background: #008348 !important;
}

.team_container .activeNBA10{
    color: #fff !important;
    background: #CE1141 !important;
}

.activeNBA10{
    color: #fff !important;
    background: #CE1141 !important;
}
.team_container .activeNBA11{
    color: #fff !important;
    background: #000000 !important;
}

.activeNBA11{
    color: #fff !important;
    background: #000000 !important;
}

.team_container .activeNBA12{
    color: #fff !important;
    background: #FDB81C !important;
}

.activeNBA12{
    color: #fff !important;
    background: #FDB81C !important;
}

.team_container .activeNBA13{
    color: #fff !important;
    background: #002D62 !important;
}

.activeNBA13{
    color: #fff !important;
    background: #002D62 !important;
}

.team_container .activeNBA14{
    color: #fff !important;
    background: #1D428A !important;
}

.activeNBA14{
    color: #fff !important;
    background: #1D428A !important;
}

.team_container .activeNBA15{
    color: #fff !important;
    background: #00471B !important;
}

.activeNBA15{
    color: #fff !important;
    background: #00471B !important;
}

.team_container .activeNBA16{
    color: #fff !important;
    background: #0C2340 !important;
}

.activeNBA16{
    color: #fff !important;
    background: #0C2340 !important;
}

.team_container .activeNBA17{
    color: #fff !important;
    background: #002B5C !important;
}

.activeNBA17{
    color: #fff !important;
    background: #002B5C !important;
}

.team_container .activeNBA18{
    color: #fff !important;
    background: #007AC1 !important;
}

.activeNBA18{
    color: #fff !important;
    background: #007AC1 !important;
}

.team_container .activeNBA19{
    color: #fff !important;
    background: #E03A3E !important;
}

.activeNBA19{
    color: #fff !important;
    background: #E03A3E !important;
}

.team_container .activeNBA20{
    color: #fff !important;
    background: #0E2240 !important;
}

.activeNBA20{
    color: #fff !important;
    background: #0E2240 !important;
}

.team_container .activeNBA21{
    color: #fff !important;
    background: #5D76A9 !important;
}

.activeNBA21{
    color: #fff !important;
    background: #5D76A9 !important;
}

.team_container .activeNBA22{
    color: #fff !important;
    background: #000000 !important;
}

.activeNBA22{
    color: #fff !important;
    background: #000000 !important;
}

.team_container .activeNBA23{
    color: #fff !important;
    background: #002B5C !important;
}

.activeNBA23{
    color: #fff !important;
    background: #002B5C !important;
}

.team_container .activeNBA24{
     color: #fff !important;
     background: #000000 !important;
}

.activeNBA24{
     color: #fff !important;
     background: #000000 !important;
}

.team_container .activeNBA25{
    color: #fff !important;
    background: #0064B1 !important;
}

.activeNBA25{
    color: #fff !important;
    background: #0064B1 !important;
}

.team_container .activeNBA26{
    color: #fff !important;
    background: #1D428A !important;
}

.activeNBA26{
    color: #fff !important;
    background: #1D428A !important;
}

.team_container .activeNBA27{
    color: #fff !important;
    background: #552583 !important;
}

.activeNBA27{
    color: #fff !important;
    background: #552583 !important;
}
.team_container .activeNBA28{
    color: #fff !important;
    background: #C8102E !important;
}

.activeNBA28{
    color: #fff !important;
    background: #C8102E !important;
}

.team_container .activeNBA29{
    color: #fff !important;
    background: #1D1160 !important;
}

.activeNBA29{
    color: #fff !important;
    background: #1D1160 !important;
}
.team_container .activeNBA30{
    color: #fff !important;
    background: #5A2B81 !important;
}

.activeNBA30{
    color: #fff !important;
    background: #5A2B81 !important;
}

/*   NFL  */

.team_container .activeNFL1{
    color: #fff !important;
    background: #97233F !important;
}
.activeNFL1{
    color: #fff !important;
    background: #97233F !important;
}
.team_container .activeNFL2{
    color: #fff !important;
    background: #000000 !important;
}
.activeNFL2{
    color: #fff !important;
    background: #000000 !important;
}
.team_container .activeNFL3{
    color: #fff !important;
    background: #24135F !important;
}
.activeNFL3{
    color: #fff !important;
    background: #24135F !important;
}
.team_container .activeNFL4{
    color: #fff !important;
    background: #C60C30 !important;
}

.activeNFL4{
    color: #fff !important;
    background: #C60C30 !important;
}
.team_container .activeNFL5{
    color: #fff !important;
    background: #000000 !important;
}
.activeNFL5{
    color: #fff !important;
    background: #000000 !important;
}
.team_container .activeNFL6{
    color: #fff !important;
    background: #0B162A !important;
}
.activeNFL6{
    color: #fff !important;
    background: #0B162A !important;
}
.team_container .activeNFL7{
    color: #fff !important;
    background: #000000 !important;
}
.activeNFL7{
    color: #fff !important;
    background: #000000 !important;
}
.team_container .activeNFL8{
    color: #fff !important;
    background: #311D00 !important;
}
.activeNFL8{
    color: #fff !important;
    background: #311D00 !important;
}
.team_container .activeNFL9{
    color: #fff !important;
    background: #002244 !important;
}
.activeNFL9{
    color: #fff !important;
    background: #002244 !important;
}
.team_container .activeNFL10{
    color: #fff !important;
    background: #FC4C02 !important;
}
.activeNFL10{
    color: #fff !important;
    background: #FC4C02 !important;
}
.team_container .activeNFL11{
    color: #fff !important;
    background: #0076B6 !important;
}
.activeNFL11{
    color: #fff !important;
    background: #0076B6 !important;
}
.team_container .activeNFL12{
    color: #fff !important;
    background: #203731 !important;
}
.activeNFL12{
    color: #fff !important;
    background: #203731 !important;
}
.team_container .activeNFL13{
     color: #fff !important;
     background: #03202F !important;
 }
.activeNFL13{
     color: #fff !important;
     background: #03202F !important;
 }
.team_container .activeNFL14{
    color: #fff !important;
    background: #013369 !important;
}
.activeNFL14{
    color: #fff !important;
    background: #013369 !important;
}
.team_container .activeNFL15{
    color: #fff !important;
    background: #000000 !important;
}
.activeNFL15{
    color: #fff !important;
    background: #000000 !important;
}
.team_container .activeNFL16{
    color: #fff !important;
    background: #E31837 !important;
}
.activeNFL16{
    color: #fff !important;
    background: #E31837 !important;
}
.team_container .activeNFL19{
    color: #fff !important;
    background: #008E97 !important;
}
.activeNFL19{
    color: #fff !important;
    background: #008E97 !important;
}
.team_container .activeNFL20{
    color: #fff !important;
background: #4F2683 !important;
}
.activeNFL20{
    color: #fff !important;
background: #4F2683 !important;
}
.team_container .activeNFL21{
    color: #fff !important;
    background: #002244 !important;
}
.activeNFL21{
    color: #fff !important;
    background: #002244 !important;
}
.team_container .activeNFL22{
    color: #fff !important;
    background: #D3BC8D !important;
}
.activeNFL22{
    color: #fff !important;
    background: #D3BC8D !important;
}
.team_container .activeNFL23{
    color: #fff !important;
    background: #A71930 !important;
}
.activeNFL23{
    color: #fff !important;
    background: #A71930 !important;
}
.team_container .activeNFL24{
    color: #fff !important;
    background: #115740 !important;
}
.activeNFL24{
    color: #fff !important;
    background: #115740 !important;
}
.team_container .activeNFL25{
    color: #fff !important;
    background: #A5ACAF !important;
}
.activeNFL25{
    color: #fff !important;
    background: #A5ACAF !important;
}
.team_container .activeNFL26{
    background: #004851 !important;
    color: #fff !important;
}
.activeNFL26{
    background: #004851 !important;
    color: #fff !important;
}
.team_container .activeNFL28{
    color: #fff !important;
    background: #000000 !important;
}
.activeNFL28{
    color: #fff !important;
    background: #000000 !important;
}
.team_container .activeNFL29{
    background: #0080C6 !important;
    color: #fff !important;
}
.activeNFL29{
    background: #0080C6 !important;
    color: #fff !important;
}
.team_container .activeNFL30 {
    color: #fff !important;
    background: #002244 !important;
}
.activeNFL30 {
    color: #fff !important;
    background: #002244 !important;
}
.team_container .activeNFL31{
    color: #fff !important;
    background: #AA0000 !important;
}
.activeNFL31{
    color: #fff !important;
    background: #AA0000 !important;
}
.team_container .activeNFL32{
    color: #fff !important;
    background: #003594 !important;
}
.activeNFL32{
    color: #fff !important;
    background: #003594 !important;
}
.team_container .activeNFL33{
    color: #fff !important;
    background: #A71930 !important;
}
.activeNFL33{
    color: #fff !important;
    background: #A71930 !important;
}
.team_container .activeNFL34{
    color: #fff !important;
    background: #002244 !important;
}
.activeNFL34{
    color: #fff !important;
    background: #002244 !important;
}
.team_container .activeNFL35{
    color: #fff !important;
    background: #5A1414 !important;
}
.activeNFL35{
    color: #fff !important;
    background: #5A1414 !important;
}


.MuiAccordionSummary-content{
    margin: 0 !important;
}
ul{
    padding: 0;
}
ul>li{
    list-style: none;
}
.MuiAccordionSummary-root.Mui-expanded{
    min-height: 40px !important;
    max-height: 40px !important;
}
.MuiAccordionSummary-root{
    padding: 0 !important;
    min-height: 40px !important;
    max-height: 40px !important;
}
.collapse_container>.collapse_single .text{
    font-size: 12px;
    text-transform: capitalize;
    display: inline-block;
    min-width: 67px;
    flex: 1;
}
.collapse_container>.collapse_single .input{
    flex: 1;
}
.MuiAccordionDetails-root {
    padding: 0px 7px 5px !important;
}
.collapse_single .input .toggle_btn{
    padding: 5px 10px;
    min-width: 74px;
    text-align: center;
    color: #333;
    border: 1px solid #ddd;
    margin-left: 5px;
    font-size: 10px;
    min-height: 29px;
}
.collapse_single .input .toggle_btn:hover, .MuiToggleButton-root.Mui-selected{
    background: #123c6c !important;
    color: #fff !important;
}
.pd-0{
    padding: 0;
}
.collapse_container .collapse_single{
    margin-bottom: 10px;
    display: flex;
}
.MuiAccordionSummary-content.Mui-expanded, .MuiAccordionSummary-content{
    display: initial;
}
.overflow_list{
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.listFooterContainer{
    display: flex;
    background: #fff;
    padding: 10px;
}
.MuiButton-containedPrimary{
    background: #123C6C !important;
    color: #fff;
}
.listFooterContainer .MuiButton-containedPrimary{
    margin: 0 5px !important;
    flex: 1;
}
.collapse_container{
    flex: 1;
}
.MuiCollapse-wrapper{
    border-top: 1px solid #ddd;
    padding-top: 15px;
}
.search_input .MuiFormLabel-root{
    line-height: 0 !important;
}
.MuiButton-contained.Mui-disabled{
    color: #fff !important;
}
.MuiButton-root {
    padding: 6px 7px !important;
}
.toggle_btn.active{
    color: #fff !important;
    background-color: #123C6C !important;
    border-color: #123C6C !important;
}
.collapse_single .dateTimeText{
    font-size: 12px;
    flex: 1;
    color: #123c6c;
    font-weight: 600;
}
.simulationSpinner{
    min-width: 86px !important;
}